import React from 'react';
import { gsap } from './plugins';
import { Slider } from './components';
import { About, Footer, Header, Skills, Testimonial, Work } from './container';
import { Navbar } from './components';
import './App.scss';

window.onload = function() {
const tl = gsap.timeline({ defaults: { ease: "power1.out" } });
  tl.to(".intro__quote", { y: "0%", duration: 1, stagger: 0.25 });
  tl.to(".slider", { y: "-100%", duration: 1, delay: 0.5, stagger: 0.25 });  
  tl.to(".intro", { y: "-100%", duration: .75 }, "-=1");
  tl.fromTo("nav", { opacity: 0 }, { opacity: 1, duration: .2 }) 
  tl.fromTo(".app_header-profile", { opacity: 0 }, { opacity: 1, duration: .1 })  
  tl.fromTo(".app__header-badge", { opacity: 0 }, { opacity: 1, duration: .3 })                   
}

const App = () => {
  return (
   <div className="app">
    <Slider />
    <Navbar />
    <Header />
    <About />
    <Work />
    <Skills />
    <Testimonial />
    <Footer />
   </div>
  );
}

export default App;