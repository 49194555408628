import React from 'react';
import './Slider.scss';

const Slider = () => {
  return (
    <main>
        <div className="intro">
          <div className="intro__text">
              <h1 className="intro__hide">
                  <span className="intro__quote">Do now</span>
              </h1>
              <h1 className="intro__hide">
                  <span className="intro__quote">What you don't have to do later.</span>
              </h1>
          </div>
         </div>
      <div className="slider"></div>
    </main>
    
  );
}

export default Slider;