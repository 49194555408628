import React from 'react';
import { motion } from 'framer-motion';
import { AppWrap } from '../../wrapper';

import { images } from '../../constants';
import './Header.scss';

//const scaleVariants = {
//  whileInView: {
//    scale: [0, 1],
//    opacity: [0, 1],
//    transition: {
//      duration: 1, 
//      ease: 'easeInOut'
//    }
//  }
//}

const Header = () => {
  return (
    <div id="home" className="app__header app__flex">
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__header-info"
      >
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
            <div style={{ marginLeft: 20 }}>
              <p className="p-text">Hello, I am</p>
              <h1 className="head-text">Julio Jimenez</h1>
              <p className="p-text">Product Manangement</p>
              <p className="p-text">UI/UX Designer</p>
              <p className="p-text">Graphic Designer</p>
            </div>
          </div>
        </div>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [.2, 1], scale: [0.2, 1] }}
        transition={{ duration: 0.3, ease: 'easeOut', delayChildren: 0.9}}
        className="app__header-img"
      >
        {[images.rightProfile, images.leftProfile, images.profile].map((profile, index) => (
          <div className="app_header-profile app__flex" key={`profile-${index}`}>
            <img src={profile} alt="profile" />
          </div>
        ))}
        
        <motion.img
          whileInView={{ scale: [1, 1.8, 1.5] }}
          transition={{ duration: .5, ease: 'easeInOut'}}
          src={images.triangle}
          className="overlay_circle"
          alt="profile_circle"
        />

      </motion.div>
    </div>
    

  );
}

export default AppWrap(Header, 'home');